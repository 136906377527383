import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '../../../../../melabev-types/src/enums/languages';


@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  currentLanguage: Languages;

  constructor(
    private translate: TranslateService,
  ) {

    const lang = this.getLocalStorageLanguage();

    switch (lang) {
      case 'en':
        this.changeLanguage(Languages.en);
        break;

      default:
        this.changeLanguage(Languages.he);
        break;
    }

  }

  changeLanguage(language: Languages) {
    this.translate.setDefaultLang(language);
    this.currentLanguage = language;
    this.setLocalStorageLanguage(language);
  }

  setLocalStorageLanguage(language: Languages) {
    localStorage.setItem('lang', language);
  }

  getLocalStorageLanguage(): Languages {
    return localStorage.getItem('lang') as Languages;
  }
}
