import { MultiLanguageText } from './multiLanguageText';
import { Languages } from '../enums/languages';
import { supportedLanguages } from '../constants/supported-languages';
export interface GuideInterface {
    guideId: string;
    email: string;
    firstName: MultiLanguageText;
    lastName: MultiLanguageText;
}

export class Guide implements GuideInterface {
    guideId: string;
    email: string;
    firstName: MultiLanguageText;
    lastName: MultiLanguageText;

    constructor(guideId: string, email: string, firstName: any, lastName: any) {
        this.guideId = guideId;
        this.email = email;
        this.firstName = new MultiLanguageText(firstName);
        this.lastName = new MultiLanguageText(lastName);
    }

    static fromFirestore(snap: any) {
        const data = snap.data() ?? {};
        Object.assign(data, { guideId: snap.id })
        return this.fromMap(data);
    }

    static fromMap(map: any) {
        if (map.firstNameMap) {
            map.firstName = map.firstNameMap;
            map.lastName = map.lastNameMap;
        }
        return new Guide(
            map.guideId,
            map.email,
            map.firstName ? map.firstName : new MultiLanguageText(''),
            map.lastName ? map.lastName : new MultiLanguageText('')
        );
    }

    fullName(lang: Languages): string {
        return `${this.firstName.get(lang)} ${this.lastName.get(lang)}`;
    }

    toMap() {
        return {
            guideId: this.guideId,
            email: this.email,
            firstName: this.firstName.toMap(),
            lastName: this.lastName.toMap(),
        };
    }

    valid() {
        return this.email && this.firstName.valid() && this.lastName.valid();
    }

    public get allName(): string {
        let name = '';
        for (let i = 0; i < supportedLanguages.length; i++) {
            const lang = supportedLanguages[i];
            name += this.fullName(lang) + ' - '
        }
        return name;
    }

    searchName(txt: string) {
        return this.allName.indexOf(txt) !== -1;
    }
}