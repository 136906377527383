import { MultiLanguageText } from './multiLanguageText';
import { Languages } from '../enums/languages';
import { supportedLanguages } from '../constants/supported-languages';

export class User {
    uid: string;
    userId: string;
    email: string;
    birthday: Date;
    userCode: number;
    orgId: number;
    firstName: MultiLanguageText;
    lastName: MultiLanguageText;

    constructor(uid: string, userId: string, email: string, birthday: Date, userCode: number, orgId: number, firstName: MultiLanguageText, lastName: MultiLanguageText) {
        this.uid = uid;
        this.orgId = orgId;
        this.userCode = userCode;
        this.userId = userId;
        this.email = email;
        this.birthday = birthday;
        this.firstName = firstName;
        this.lastName = lastName;
    }

    static fromFirestore(snapshot: any) {
        const data: any = snapshot.data() ?? {};
        Object.assign(data, { uid: snapshot.id });
        try {
            data['birthday'] = data['birthday'].toDate();
        } catch (error) {
            console.log('Error User.fromFirestore', error);
            return null;
        }
        
        if (data.firstNameMap) {
            data.firstName = data.firstNameMap;
            data.lastName = data.lastNameMap;
        }
        
        return this.fromMap(data);
    }

    static fromMap(map: any) {
        return new User(map['uid'], map['userId'], map['email'], map['birthday'], map['userCode'], map['orgId'], new MultiLanguageText(map['firstName'] ?? ''), new MultiLanguageText(map['lastName'] ?? ''));
    }

    static register(userId: string, email: string, birthday: Date, firstName: string, lastName: string) {
        return User.fromMap({
            uid: null,
            userId,
            email,
            birthday,
            userCode: 0,
            orgCode: 0,
            firstName,
            lastName
        });
    }


    fullName(lang: Languages) {
        return `${this.firstName.get(lang)} ${this.lastName.get(lang)}`;
    }

    valid(): boolean {
        return this.lastName.valid() && this.firstName.valid() && !!this.birthday && !!this.userId && !!this.email;
    }

    public get allName(): string {
        let name = '';
        for (let i = 0; i < supportedLanguages.length; i++) {
            const lang = supportedLanguages[i];
            name += this.fullName(lang) + ' - '
        }
        return name;
    }

    toMap() {
        return {
            uid: this.uid ?? null,
            userId: this.userId ?? null,
            email: this.email ?? null,
            birthday: this.birthday ?? null,
            orgId: this.orgId ?? null,
            firstName: this.firstName.toMap(),
            lastName: this.lastName.toMap()
        };
    }

}