
export class Admin {

    /**
     * FirebaseUser
     */
    user: any;
    admin: boolean;

    constructor(user: any) {
        this.user = user;
        this.admin = true;
    }
}