import { supportedLanguages } from "../constants";
import { Languages } from '../enums/languages';

export class MultiLanguageText {

    value = new Map<Languages, string>();

    constructor(value: any) {
        if (typeof value === 'string') {
            supportedLanguages.forEach(lang => {
                this.set(lang, value);
            });
        } else {
            supportedLanguages.forEach(lang => {
                if (value[lang]) {
                    this.set(lang, value[lang]);
                }
            });
        }
    }

    set(language: Languages, value: string) {
        this.value.set(language, value);
    }

    get(language: Languages): string {
        if (this.value.has(language)) return this.value.get(language) ?? '';

        for (let i = 0; i < supportedLanguages.length; i++) {
            const lang = supportedLanguages[i];
            if (this.value.has(lang)) {
                return this.value.get(lang) ?? '';
            }
        }

        return '';
    }

    search(value: string): boolean {

        for (let i = 0; i < supportedLanguages.length; i++) {
            const lang = supportedLanguages[i];
            if (this.value.get(lang)?.indexOf(value) !== -1) return true;

        }

        return false;
    }


    public get all(): string {
        let name = '';
        
        for (let i = 0; i < supportedLanguages.length; i++) {
            const lang = supportedLanguages[i];
            name += this.get(lang) + ' - '
        }
        return name;
    }


    toMap() {
        const data: any = {};
        supportedLanguages.forEach(lang => {
            this.value.has(lang) ? data[lang] = this.value.get(lang) : null;
        });
        return data;
    }

    valid(): boolean {
        return this.value.size > 0;
    }
}