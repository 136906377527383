import { supportedLanguages } from '../constants/supported-languages';
import { MultiLanguageText } from './multiLanguageText';

export class Group {

    static ALL = new Group('all', new MultiLanguageText({en: 'All', he: 'כולם'}))

    constructor(
        public groupId: string,
        public name: MultiLanguageText,
    ) { }

    static fromMap(data: any) {
        return new Group(data.groupId, data.name ? new MultiLanguageText(data.name) : new MultiLanguageText(''))
    }

    static fromFirestore(snap: any) {
        console.log('Group.fromFirestore', snap.data());
        return this.fromMap({ ...(snap.data() ?? {}) });
    }

    public get allName(): string {
        let name = '';

        for (let i = 0; i < supportedLanguages.length; i++) {
            const lang = supportedLanguages[i];
            name += this.name.get(lang) + ' - '
        }

        return name;
    }

    toMap() {
        return {
            groupId: this.groupId,
            name: this.name.toMap(),
        }
    }
}