import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from './services/auth/auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslationService } from './services/translation/translation.service';
import { Languages } from '../../../melabev-types/src/enums/languages';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'melabev';
  cookieUserID: string;
  user: Observable<any>;

  constructor(private translate: TranslationService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public auth: AuthService,
    private router: Router,
  ) {

    this.matIconRegistry.addSvgIcon(
      'USA_flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/pictures/USA_flag.svg'));
    this.matIconRegistry.addSvgIcon(
      'ISRAEL_flag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/pictures/ISRAEL_flag.svg'));
    this.matIconRegistry.addSvgIcon(
      'clock',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/pictures/schedule.svg'));

    if (this.isMobile()) {
      console.log('Is mobile')
      this.interval();
    }
  }

  interval() {
    const el = document.getElementById('zmmtg-root');
    if (el != null) {
      el.remove();
    } else {
      setTimeout(() => {
        const el = document.getElementById('zmmtg-root');
        if (el != null) {
          el.remove();
          document.body.classList.add('normalize');
          document.body.setAttribute('style', 'width: 100vw !important')
        } else {
          this.interval();
        }
      }, 1000);
    }
  }

  changeLanguageToEnglish() {
    this.translate.changeLanguage(Languages.en);
  }
  changeLanguageToHebrew() {
    this.translate.changeLanguage(Languages.he);
  }

  async logout() {
    await this.auth.logout();
    this.router.navigateByUrl('/');
  }

  isMobile() {
    return (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i))
  }


}
