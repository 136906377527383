import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import { Guide } from '../../../../../melabev-types/src/classes/guide';
import { User } from '../../../../../melabev-types/src/classes/user';
import { Activity } from '../../../../../melabev-types/src/classes/activity';
import { Group } from '../../../../../melabev-types/src/classes/group';
import { ILogEntry } from '../../../../../melabev-types/src/interfaces/log-entry';

@Injectable({
  providedIn: 'root'
})
// @ts-ignore
export class DatabaseService {

  usersCollectionRef: AngularFirestoreCollection;
  activitiesCollectionFromTodayRef: AngularFirestoreCollection;
  activitiesCollectionRef: AngularFirestoreCollection;
  guidesCollectionRef: AngularFirestoreCollection;
  groupsCollectionRef: AngularFirestoreCollection;
  logsCollectionRef: AngularFirestoreCollection;

  today = new Date();

  constructor(private firestore: AngularFirestore) {
    this.today.setHours(0, 0, 0, 0);
    console.log(this.today);
    this.usersCollectionRef = this.firestore.collection('users');
    this.activitiesCollectionFromTodayRef = this.firestore.collection('activities', ref => ref.where('date', '>=', this.today));
    this.activitiesCollectionRef = this.firestore.collection('activities', ref => ref.orderBy('date', 'desc'));
    this.guidesCollectionRef = this.firestore.collection('guides');
    this.groupsCollectionRef = this.firestore.collection('groups');
    this.logsCollectionRef = this.firestore.collection('logs');

  }

  public get key(): string {
    return this.firestore.createId();
  }


  getUserDocument(uid: string): Observable<User> {
    return this.usersCollectionRef
      .doc(uid)
      .snapshotChanges()
      .pipe(
        map(user => User.fromFirestore(user.payload))
      );
  }

  async updateUserDocument(user: User): Promise<any> {
    return await this.usersCollectionRef.doc(user.uid).update(user.toMap());
  }

  async createUserDocument(user: User): Promise<boolean> {
    try {
      const ref = await this.usersCollectionRef.add(user.toMap());
      console.log('User created', ref.id);
      user.uid = ref.id;
      await this.updateUserDocument(user);
      console.log('User updated');
      return true;
    } catch (error) {
      console.error('Error createUserDocument', user, error);
      return false;
    }
  }

  getAllActivities(groups: Group[]): Observable<Activity[]> {

    console.log('Groups', groups);

    if (!groups.length) {
      return this.firestore
        .collection('activities',
          ref => ref.where('group.groupId', '==', 'all'
          )
            .where('date', '>=', this.today))
        .snapshotChanges()
        .pipe(
          map(activities =>
            activities.map(activity => Activity.fromFirestore(activity.payload.doc))
          )
        );
    };

    return this.firestore
      .collection('activities',
        ref => ref.where('group.groupId', 'in',
          [...groups.map(g => g.groupId), 'all']
        )
          .where('date', '>=', this.today))
      .snapshotChanges()
      .pipe(
        map(activities =>
          activities.map(activity => Activity.fromFirestore(activity.payload.doc))
        )
      );
  }

  getAllActivitiesAtAllTimes(): Observable<Activity[]> {
    return this.activitiesCollectionRef
      .snapshotChanges()
      .pipe(
        map(activities =>
          activities.map(activity => Activity.fromFirestore(activity.payload.doc))
        ),
        tap(console.log)
      );
  }

  getActivitiesByGuideId(guideId: string): Observable<Activity[]> {
    return this.guidesCollectionRef
      .doc(guideId)
      .collection('guidesActivities')
      .snapshotChanges()
      .pipe(
        map(activities =>
          activities.map(activity => Activity.fromFirestore(activity.payload.doc))
        )
      );
  }

  async createActivity(activity: Activity): Promise<any> {
    activity.activityId = this.firestore.createId();
    const batch = this.firestore.firestore.batch();
    batch.set(
      this.guidesCollectionRef.doc(`${activity.guide.guideId}/guidesActivities/${activity.activityId}`).ref,
      activity.toMap(),
    );
    batch.set(
      this.activitiesCollectionRef.doc(`${activity.activityId}`).ref,
      activity.toMap(),
    );
    return await batch.commit();
  }

  async updateActivity(activity: Activity): Promise<any> {
    // TODO: Check if it's not the same Guide and update his collection too
    return await this.activitiesCollectionRef.doc(activity.activityId).update(activity.toMap());
  }

  async deleteActivity(activityId: string): Promise<any> {
    // TODO: Erase from the Guide collection
    return await this.activitiesCollectionRef.doc(activityId).delete();
  }

  getGuides(): Observable<Guide[]> {
    return this.guidesCollectionRef
      .snapshotChanges()
      .pipe(
        map(guides =>
          guides.map(guide => Guide.fromFirestore(guide.payload.doc))
        ),
        tap(console.log)
      );
  }

  getGuideDocument(guideId: string): Observable<Guide> {
    return this.guidesCollectionRef
      .doc(guideId)
      .snapshotChanges()
      .pipe(
        map(guide => Guide.fromFirestore(guide.payload))
      );
  }

  async createGuide(guide: Guide): Promise<any> {
    guide.guideId = this.firestore.createId();
    return await this.guidesCollectionRef.add(guide.toMap());
  }

  async updateGuide(guide: Guide): Promise<any> {
    return await this.guidesCollectionRef.doc(`${guide.guideId}`).update(guide.toMap());
  }

  async deleteGuide(guideId: string): Promise<any> {
    return await this.guidesCollectionRef.doc(guideId).delete();
  }




  getUsers(): Observable<User[]> {
    return this.usersCollectionRef
      .snapshotChanges()
      .pipe(
        map(users =>
          users.map(user => User.fromFirestore(user.payload.doc)),
        )
      );
  }

  async createUser(user: User): Promise<any> {
    user.uid = this.firestore.createId();
    return await this.usersCollectionRef.doc(user.uid).set(user.toMap());
  }

  async updateUser(user: User): Promise<any> {
    return await this.usersCollectionRef.doc(`${user.uid}`).update(user.toMap());
  }

  async deleteUser(uid: string): Promise<any> {
    return await this.usersCollectionRef.doc(uid).delete();
  }

  getUserGroups(uid: string) {
    console.log('UID', uid);
    return this.usersCollectionRef
      .doc(uid)
      .collection('userGroups')
      .get()
      .pipe(
        map(s => s.docs.map(g => Group.fromFirestore(g))),
        tap(console.log)
      )
      .toPromise();
  }

  getGroups() {
    return this.groupsCollectionRef
      .snapshotChanges()
      .pipe(
        map(s => s.map(g => Group.fromFirestore(g.payload.doc)))
      )
  }

  createGroup(group: Group) {
    group.groupId = this.firestore.createId();
    return this.groupsCollectionRef.doc(group.groupId).set(group.toMap());
  }

  deleteGroup(groupId: string) {
    return this.groupsCollectionRef.doc(groupId).delete();
  }

  async removeAllUserGroups(userId: string) {

    const groups = await this.usersCollectionRef.doc(userId).collection('userGroups').get().toPromise();

    console.log('User groups', groups);

    for await (const group of groups.docs) {
      await this.removeUserFromGroup(userId, Group.fromFirestore(group));
    }

  }

  async removeUserFromGroup(userId: string, group: Group) {

    return await this.groupsCollectionRef
      .doc(group.groupId)
      .collection('groupUsers')
      .doc(userId)
      .delete();

  }

  async addUserToGroup(user: User, group: Group) {
    return await this.groupsCollectionRef
      .doc(group.groupId)
      .collection('groupUsers')
      .doc(user.uid)
      .set(user.toMap());
  }

  getLogs() {
    const d = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
    console.log(d)
    return this.firestore
      .collection('logs',
        ref => ref
          .where('date', '>', d)
          .orderBy('date', 'desc')
      )
      .snapshotChanges()
      .pipe(
        tap(console.log),
        map(snap => snap.map(s => {

          const entry: ILogEntry = s.payload.doc.data() as any;

          entry.logData.user = User.fromMap(entry.logData.user);
          entry.logData.meeting = Activity.fromMap(entry.logData.meeting);

          entry.date = (entry.date as any).toDate();

          return entry;
        }))
      );
  }
}
