<!-- 
<div class="toolbar" role="banner">
  &nbsp;
  <div *ngIf="auth.user$ | async as u">
    <button (click)="logout()" *ngIf="u !== null">
      <mat-icon>power_settings_new</mat-icon>
    </button>
  </div>

  <button class="round-button" dir="rtl" (click)="changeLanguageToEnglish()">
    <mat-icon svgIcon="USA_flag"></mat-icon>
  </button>
  &nbsp;
  <button class="round-button" dir="rtl" (click)="changeLanguageToHebrew()">
    <mat-icon svgIcon="ISRAEL_flag"></mat-icon>
  </button>
  <svg
    id="video"
    viewBox="0 0 100 100"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>our website</title>
    <defs></defs>
    <g
      id="25.-Video"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g
        transform="translate(2.000000, 8.000000)"
        stroke="#e6ebf1"
        stroke-width="4"
      >
        <rect id="Layer-1" x="0" y="0" width="96" height="84.48" rx="20"></rect>
        <path
          d="M36.48,24.9370316 C36.48,23.2787363 37.6310215,22.6274848 39.0422854,23.4772465 L67.6394029,40.6963747 C69.054514,41.5484529 69.0506668,42.9322612 67.6394029,43.7820228 L39.0422854,61.0011511 C37.6271742,61.8532293 36.48,61.1928227 36.48,59.541366 L36.48,24.9370316 Z"
          id="Layer-2"
        ></path>
      </g>
    </g>
  </svg>
  <span>{{ "home.welcome" | translate }}</span>
  <div class="spacer"></div>
  <a
    aria-label="melabev homepage"
    target="_blank"
    rel="noopener"
    href="https://www.melabev.org.il/"
    title="melabev"
  >
    <img
      id="logo"
      width="60"
      alt="melabev logo"
      src="../assets/pictures/logo.png"
    />
  </a>
</div>
-->
<router-outlet></router-outlet>
<!--
<footer>
  <div  style="width:100%; text-align: right;vertical-align: middle;"> 
    <div class="rightsReserved"><a>{{ "home.copyright" | translate }}</a></div>
     <div class="levtechFooterDiv"  >
      <img
      id="logo"
      width="60"
      alt="levtech logo"
      src="../assets/pictures/levtech_logo.png"
    /> &nbsp;&nbsp;
    {{"home.madeWith" | translate}}
    
    </div> </div>
 
  
</footer>

-->