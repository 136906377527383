import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../environments/environment';
import { ZoomMeeting } from '../../../../../melabev-types/src/interfaces/zoom-meeting';
import { Guide } from '../../../../../melabev-types/src/classes/guide';
import { User } from '../../../../../melabev-types/src/classes/user';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  async requestUserCustomToken(userId: string, birthday: string) {
    let response;
    try {
      response = await this.http.get(
        `${environment.endpoint}/login?userId=${userId}&birthday=${birthday}`
      ).toPromise();
    } catch (e) {
      const r = e as HttpErrorResponse;
      throw (r.error)['error'];
    }

    if (response['success']) {
      return response['token'];
    } else {
      throw response['error'];
    }

  }

  async getMeetingSignature(token: string, meetingOptions: ZoomMeeting) {

    // const token = environment.production ? await this.auth.getToken() : 'loremipsumdolorsitamet$';

    const response = await this.http.post(
      `${environment.endpoint}/meeting/signature`,
      {
        data: {
          meetingNumber: meetingOptions.meetingId,
          role: meetingOptions.role
        }
      },
      {
        headers: {
          authorization: 'Bearer ' + token,
        }
      }
    ).toPromise();

    if (response['success']) {
      return response['signature'];
    } else {
      throw response['error'];
    }

  }

  async getMeetingUrl(token: string, id: number, password: string) {

    const response = await this.http.post(
      `${environment.endpoint}/meeting/url`,
      {
        data: {
          meetingNumber: id,
          password,
        }
      },
      {
        headers: {
          authorization: 'Bearer ' + token,
        }
      }
    ).toPromise();

    if (response['success']) {
      return response['url'];
    } else {
      throw response['error'];
    }

  }

  async createGuide(token: string, guide: Guide, password: string) {

    const response = await this.http.put(
      `${environment.endpoint}/guide`,
      {
        data: {
          guide: guide.toMap(),
          password
        }
      },
      {
        headers: {
          authorization: 'Bearer ' + token,
        }
      }
    ).toPromise();

    if (response['success']) {
      return;
    } else {
      throw response['error'];
    }

  }

  async createUser(token: string, user: User, password: string) {

    const response = await this.http.put(
      `${environment.endpoint}/users`,
      {
        data: {
          user: user.toMap(),
          password
        }
      },
      {
        headers: {
          authorization: 'Bearer ' + token,
        }
      }
    ).toPromise();

    if (response['success']) {
      return;
    } else {
      throw response['error'];
    }

  }
}

