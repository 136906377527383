import { Injectable } from '@angular/core';
import { User as FirebaseUser } from 'firebase/app';
import { Observable, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { ApiService } from '../api/api.service';
import { switchMap, tap } from 'rxjs/operators';
import { DatabaseService } from '../database/database.service';
import { Guide } from '../../../../../melabev-types/src/classes/guide';
import { User } from '../../../../../melabev-types/src/classes/user';
import { Admin } from '../../../../../melabev-types/src/classes/admin';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  firebaseUser$: Observable<FirebaseUser>;
  firebaseUser: FirebaseUser;
  user$: Observable<User | Guide | Admin>;

  constructor(private auth: AngularFireAuth, private api: ApiService, private database: DatabaseService) {

    this.firebaseUser$ = this.auth.authState;
    this.user$ = this.firebaseUser$.pipe(
      switchMap(
        user => {
          if (user === null) {
            return of(null);
          }
          return this.getTokenObject(user);
        }
      ),
      switchMap(
        user => {
          if (user === null) { return of(null); }
          if (user.token.claims.guide) {
            return this.database.getGuideDocument(user.user.uid);
          } else if (user.token.claims.admin) {
            return of(new Admin(user.user));
          }
          return this.database.getUserDocument(user.user.uid);
        }
      )
    );

    this.firebaseUser$.pipe(tap(f => this.firebaseUser = f)).subscribe();
  }

  async getUserType() {
    const user = await this.auth.currentUser;
    if (user === null) { return null; }
    const token = await this.getTokenObject(user);
    if (token.token.claims.guide) { return 'Guide'; }
    else if (token.token.claims.admin) { return 'Admin'; }
    else { return 'User'; }
  }

  async getTokenObject(user: FirebaseUser): Promise<{ token: firebase.auth.IdTokenResult, user: FirebaseUser }> {
    const token = await user.getIdTokenResult();
    return { token, user };
  }

  async loginWithEmailAndPassword(email: string, password: string) {
    try {
      const res = await this.auth.signInWithEmailAndPassword(email, password);
      return res.user.uid;
    } catch (error) {
      console.error('Error loginWithEmailAndPassword', email, error);
      throw error;
    }
  }

  async login(userId: string, birthday: string) {
    let customToken;
    try {
      customToken = await this.api.requestUserCustomToken(userId, birthday);
    } catch (error) {
      return error;
    }
    try {
      await this.auth.signInWithCustomToken(customToken);
    } catch (error) {
      return new Error('ERROR_SIGNIN');
    }
    return true;
  }

  // async register(user: User) {
  //   try {
  //     await this.database.createUserDocument(user);
  //     return await this.login(user.userId, user.birthday);
  //   } catch (error) {
  //     console.error('Error register', user, error);
  //   }
  // }

  async getToken() {
    const currentUser = await this.auth.currentUser;
    if (currentUser === null) return null;
    return await currentUser.getIdToken();
  }

  async logout() {
    return this.auth.signOut();
  }
}
