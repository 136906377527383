import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { UserGuard } from './guards/user.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/user/users.module').then(m => m.UsersModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
  },
  // {
  //   path: 'admin',
  //   pathMatch: 'full',
  //   component: ManagerLoginComponent
  // },
  // {
  //   path: 'admin/activities',
  //   pathMatch: 'full',
  //   component: ManageActivitiesComponent,
  //   canActivate: [AdminGuard],
  // },
  // {
  //   path: 'admin/guides',
  //   pathMatch: 'full',
  //   component: ManageGuidesComponent,
  //   canActivate: [AdminGuard],
  // },
  {
    path: '',
    component: AppComponent,
    canActivate: [UserGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
