import { Time } from "../types";
import { ZoomMeetingInfo } from '../interfaces/zoom-meeting-info';
import { Guide } from './guide';
import { MultiLanguageText } from './multiLanguageText';
import { supportedLanguages } from '../constants/supported-languages';
import { Group } from './group';

export class Activity {
    activityId: string;
    name: MultiLanguageText;
    date: Date;
    startTime: Time;
    endTime: Time;
    language: string;
    meetingInfo: ZoomMeetingInfo;
    guide: Guide | null;
    group: Group | null;

    constructor(activityId: string, name: MultiLanguageText, date: any,
        startTime: Time, endTime: Time, language: string, meetingInfo: ZoomMeetingInfo, guide: Guide | null, group: Group | null) {
        this.activityId = activityId;
        this.name = name;
        this.date = date;
        this.startTime = startTime;
        this.endTime = endTime;
        this.language = language;
        this.meetingInfo = meetingInfo;
        this.guide = guide;
        this.group = group;
    }

    static fromFirestore(snap: any) {
        const data = snap.data() ?? {};

        if (data.engName) {
            data.name = {
                en: data.engName,
                he: data.hebName
            }
        }

        if (!data.group) {
            data.group = Group.ALL.toMap();
        }

        return this.fromMap({ activityId: snap.id, ...data, date: data.date.toDate() });
    }

    static fromMap(map: any) {
        return new Activity(
            map.activityId,
            map.name ? new MultiLanguageText(map.name) : new MultiLanguageText(''),
            map.date,
            map.startTime ?? { hours: 0, minutes: 0 },
            map.endTime ?? { hours: 0, minutes: 0 },
            map.language,
            map.meetingInfo ?? {},
            map.guide ? Guide.fromMap(map.guide) : null,
            map.group ? Group.fromMap(map.group) : null,
        );
    }

    toMap() {
        return {
            activityId: this.activityId ?? null,
            name: this.name.toMap() ?? null,
            date: this.date ?? null,
            startTime: this.startTime ?? null,
            endTime: this.endTime ?? null,
            language: this.language ?? null,
            guide: this.guide?.toMap() ?? null,
            meetingInfo: this.meetingInfo ?? null,
            group: this.group?.toMap() ?? null,
        };
    }

    public get allName(): string {
        let name = '';
        for (const lang of supportedLanguages) {
            name += this.name.get(lang) + ' - '
        }
        return name;
    }

    public get startTimeAsString(): string {
        return this.getHourFormat(this.startTime);
    }

    public get endTimeAsString(): string {
        return this.getHourFormat(this.endTime);
    }

    getHourFormat(time: Time) {
        let h: string;
        let m: string;
        if (time.hours < 10) {
            h = '0' + time.hours;
        }
        else {
            h = `${time.hours}`;
        }
        if (time.minutes < 10) {
            m = '0' + time.minutes;
        }
        else {
            m = `${time.minutes}`;
        }
        return h + ':' + m;
    }
}
